<template>
<div>
    <el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
        <el-tab-pane label="组织" name="organization">
            <Organization ref="organization" :config="config" @onSelectedRow="onOrganizationSelectedRow">
            </Organization>
        </el-tab-pane>
        <el-tab-pane label="客商" name="customervendor">
            <CustomerVendor ref="customervendor" :config="config" @onSelectedRow="onCustomerVendorSelectedRow">
            </CustomerVendor>
        </el-tab-pane>
        <el-tab-pane label="货主" name="customer">
            <Customer ref="customer" :config="config" @onSelectedRow="onCustomerSelectedRow">
            </Customer>
        </el-tab-pane>
        <el-tab-pane label="承运商" name="carrier">
            <Carrier ref="carrier" :config="config" @onSelectedRow="onCarrierSelectedRow"></Carrier>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "organization",
            currentSelectedRows: {
                customerRows: [],
                organizationRows: [],
                carrierRows: [],
                customervendorRows: [],
            },
            selectedRow:{
                Id:0,
                Code:"",
                Name:"",
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: true,
            isSelectionDisplay: true,
        },
    },
    mounted() {

    },
    watch: {

    },
    created: function () {

    },
    methods: {
        tab1Click: function (tab, event) {
            switch (tab) {
                case "organization":
                    break;
                case "customervendor":
                    break;
                case "customer":
                    break;
                case "carrier":
                    break;
            }
        },
        onCustomerSelectedRow: function (data) {
            if (this.config.isSelectionDisplay) {
                this.currentSelectedRows.customerRows = data;
                this.$emit("onSelectedRow", this.currentSelectedRows);
            } else {
                this.selectedRow.Id=data.CustomerID;
                this.selectedRow.Code=data.CustomerCode;
                this.selectedRow.Name=data.CustomerName;
                this.$emit("onSelectedRow", this.selectedRow);
            }

        },
        onOrganizationSelectedRow(data) {
            if (this.config.isSelectionDisplay) {
                this.currentSelectedRows.organizationRows = data;
                this.$emit("onSelectedRow", this.currentSelectedRows);
            } else {
                this.selectedRow.Id=data.Id;
                this.selectedRow.Code=data.Code;
                this.selectedRow.Name=data.Name;
                this.$emit("onSelectedRow", this.selectedRow);
            }
        },
        onCarrierSelectedRow(data) {
            if (this.config.isSelectionDisplay) {
                this.currentSelectedRows.carrierRows = data;
                this.$emit("onSelectedRow", this.currentSelectedRows);
            } else {
                this.selectedRow.Id=data.MemberId;
                this.selectedRow.Code=data.MemberCode;
                this.selectedRow.Name=data.MemberName;
                this.$emit("onSelectedRow", this.selectedRow);
            }
        },
        onCustomerVendorSelectedRow(data) {
            if (this.config.isSelectionDisplay) {
                this.currentSelectedRows.customervendorRows = data;
                this.$emit("onSelectedRow", this.currentSelectedRows);
            } else {
                this.selectedRow.Id=data.VendorId;
                this.selectedRow.Code=data.VendorCode;
                this.selectedRow.Name=data.VendorName;
                this.$emit("onSelectedRow", this.selectedRow);
            }
        }
    },
    components: {
        "Customer": resolve => {
            require(['@/components/business/selector/customerselector.vue'], resolve)
        },
        "Organization": resolve => {
            require(['@/components/business/selector/organizationselector.vue'], resolve)
        },
        "CustomerVendor": resolve => {
            require(['@/components/business/selector/customervendorselector.vue'], resolve)
        },
        "Carrier": resolve => {
            require(['@/components/business/selector/carrierselector.vue'], resolve)
        },
    }
}
</script>
